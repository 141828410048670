import { Component, OnInit, Input, Host } from '@angular/core';
import { FigureNode } from 'src/app/models/node';
import { Link } from 'src/app/models/link';
import { Myth } from 'src/app/models/myth';
import { NetworkComponent } from '../../network/network.component';

@Component({
  selector: 'app-myth',
  templateUrl: './myth.component.html',
  styleUrls: ['./myth.component.scss']
})
export class MythComponent implements OnInit {

  @Input() myth: Myth;

  @Input() links: Link[];

  @Input() figures: FigureNode[];

  constructor(@Host() public network: NetworkComponent) { }

  ngOnInit(): void {
  }

  selectFigure(figure: FigureNode) {
    this.network.onFigureHoverEnd(figure);
    this.network.selectFigure(figure.id);
  }

}
