import { Component, OnInit, Input, Host } from '@angular/core';
import { Link } from 'src/app/models/link';
import { Myth } from 'src/app/models/myth';
import { NetworkComponent } from '../../network/network.component';
import { FigureNode } from 'src/app/models/node';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  @Input() link: Link;

  @Input() myths: Myth[];

  @Input() figures: Myth[];

  constructor(@Host() public network: NetworkComponent) { }

  ngOnInit(): void {
  }

  selectFigure(figure: FigureNode) {
    this.network.onFigureHoverEnd(figure);
    this.network.selectFigure(figure.id);
  }

}
