import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NetworkComponent } from './components/network/network.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './mat.module';
import { FigureComponent } from './components/sidebar/figure/figure.component';
import { MythComponent } from './components/sidebar/myth/myth.component';
import { LinkComponent } from './components/sidebar/link/link.component';
import { HistoryBarComponent } from './components/history-bar/history-bar.component';
import { LegendComponent } from './components/legend/legend.component';
import { ResetComponent } from './components/reset/reset.component';

@NgModule({
  declarations: [
    AppComponent,
    NetworkComponent,
    FigureComponent,
    MythComponent,
    LinkComponent,
    HistoryBarComponent,
    LegendComponent,
    ResetComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
