import { Component, OnInit, Host, ViewChild, OnDestroy, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NetworkComponent } from '../network/network.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-history-bar',
  templateUrl: './history-bar.component.html',
  styleUrls: ['./history-bar.component.scss']
})
export class HistoryBarComponent implements OnInit, OnDestroy {

  private historySubscription: Subscription;

  constructor(@Host() public network: NetworkComponent, 
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    // On updates to the history
    this.historySubscription = this.network.$historyUpdates.subscribe(() => {
      // Scroll the latest item into view
      setTimeout(() => {
        document.querySelector('#history > div > div.item.active').scrollIntoView();
      }, 0);
    })
  }

  ngOnDestroy() {
    this.historySubscription.unsubscribe();
  }

  checkOverflow(element) {
    if (element.offsetWidth < element.scrollWidth) {
      return true;
    } else {
      return false;
    } 
  }
}
