import { Injectable } from '@angular/core';
import { Figure } from '../models/figure';
import { Myth } from '../models/myth';
import { Link } from '../models/link';
import { myths } from '../constants/myths';
import { FigureNode } from '../models/node';
import { groupColours } from '../constants/groupColours';

@Injectable({
  providedIn: 'root'
})
export class DataSourceService {

  constructor() {}

  /**
   * Processes the json data and creates links from the figures and myths
   */
  static generateLinks(figures: Figure[], myths: Myth[]) {
    let idx = 0;
    const links = [];

    // For each myth
    for (let myth of myths) {

      // If there is more than one figure involved
      if (myth.figures.length > 1) {

        // Find all combinations of the figures
        const figureCombinations: string[][] = myth.figures.flatMap(
          (v, i) => myth.figures.slice(i + 1).map( w => [v, w])
        );

        // Check if a link already exists between each figure combination; if not, create one
        for (let combination of figureCombinations) {

          // Check if a link already exists
          const existingLink = links.find((link) => {
            return (link.source === combination[0] && link.target === combination[1]) ||
              (link.source === combination[1] && link.target === combination[0])
          });

          // If the link already exists
          if (existingLink) {
            // Add the myth ID to the link
            existingLink.myths.push(myth.id);
          } else {
            // Create a new link and add the myth to it
            const newLink = {
              id: String(idx),
              source: combination[0],
              target: combination[1],
              myths: [myth.id]
            };
            idx++;
            links.push(newLink);
          }
        }
      }
    }

    // Return the generated links
    return links;
  }

  static addGroupsToFigures(nodes: FigureNode[]) {
    for (const node of nodes) {
      let match = false;

      for (const tag of node.tags) {
        if (Object.keys(groupColours).some((key) => key === tag)) {
          node.group = tag;
          match = true
          break;
        }
      }

      if (!match) {
        node.group = "Others";
      }
    }
  }

  static getLinksForFigure(figureID: string, links: Link[]) {
    return links.filter((l) => l.source.id === figureID || l.target.id === figureID);
  }

  static getLinksForMyth(inputMyth: Myth, links: Link[]) {
    return links.filter((l) => l.myths.some((mythID) => mythID === inputMyth.id));
  }

  static getMythsForFigure(figureID: string) {
    return myths.filter((myth) => myth.figures.some((id) => id == figureID));
  }

  static getNodesForMyths(inputMyths: Myth[], nodes: FigureNode[]) {
    // For each node, return a myth if a figure within the myth is present in the node
    const figuresIDsInMyths = inputMyths.flatMap((m) => m.figures);
    if (figuresIDsInMyths.length <= 0) {
      return [];
    } else {
      return nodes.filter((node) => figuresIDsInMyths.some((f) => node.id === f));
    }
  }
}
