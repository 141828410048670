import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import * as d3 from 'd3';
import { DataSourceService } from '../../services/data-source.service';
import { figures } from '../../constants/figures';
import { myths } from '../../constants/myths';
import { FigureNode } from 'src/app/models/node';
import { Link } from 'src/app/models/link';
import { ForceLink } from 'd3';
import { SelectionTypeEnum } from 'src/app/enums/SelectionType';
import { Myth } from 'src/app/models/myth';
import { NetworkSelectedState } from 'src/app/models/networkSelectedState';
import { Subject } from 'rxjs';
import { GroupAndColour } from 'src/app/models/groupAndColour';
import { groupColours } from 'src/app/constants/groupColours';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NetworkComponent implements OnInit {

  /**
   * The main svg element
   */
  svg: any;

  /**
   * The network width
   */
  width: number;

  /**
   * The network height
   */
  height: number;
   
  /**
   * The network zoom object
   */
  zoom: any;

  /**
   * The top level container inside the svg element
   */
  main: any;

  /**
   * The currently hovered item
   */
  hovering: FigureNode | Link | Myth;

  /**
   * The currently hovered item's type
   */
  hoveringType: SelectionTypeEnum;

  /**
   * The state of what is currently selected
   */
  state: NetworkSelectedState;

  /**
   * The history of selected items
   */
  history: NetworkSelectedState[] = [];

  /**
   * A behaviour subject and observerable pair that pushes updates when the history is updated
   */
  private historyUpdates = new Subject();
  $historyUpdates = this.historyUpdates.asObservable();

  /**
   * The current selected items history index
   */
  currentHistoryIndex = -1;

  /**
   * The graph nodes, links and myths
   */
  graph: any;

  /**
   * The d3 simulation
   */
  simulation: d3.Simulation<FigureNode, Link>;

  /**
   * The simulation nodes
   */
  nodes: d3.Selection<Element | d3.EnterElement | Document | Window | SVGCircleElement, unknown, SVGGElement, unknown>;

  /**
   * The selected node circle indicator
   */
  selectedNodeCircle: any;

  /**
   * The hovering node circle indicator
   */
  hoveringNodeCircle: any;

  /**
   * The simulation links
   */
  links: any;

  /**
   * The simulation labels
   */
  label: any;

  /**
   * Groups and colours
   */
  groupsAndColours: GroupAndColour[];

  /**
   * The colour function that takes a group number as input and gives a colour as an output 
   */
  colourFunction = (group: string) => groupColours[group]; //d3.scaleOrdinal;

  /**
   * The svg element ref
   */
  @ViewChild('svg') svgRef: ElementRef;

  constructor(private cdr: ChangeDetectorRef) { }

  async ngOnInit() {
    // Initialise the selected state variable
    this.state = {
      selected: undefined,
      selectedType: undefined,
      selectedMyths: undefined,
      selectedFigures: undefined,
      selectedLinks: undefined
    };

    // Process the data
    const generatedLinks = DataSourceService.generateLinks(figures, myths);

    // Create the graph
    this.graph = {
      nodes: figures,
      links: generatedLinks,
      myths: myths
    };

    // Generate the node groups
    DataSourceService.addGroupsToFigures(this.graph.nodes);

    // Calculate the size of the nodes
    for (let node of this.graph.nodes) {
      const mythAppearances = myths.filter((myth) => myth.figures.some((figure) => figure === node.id)).length;
      node.size = 5 + Math.ceil(mythAppearances * 0.5);
    }

    // Select the svg element
    this.svg = d3.select("#mythology");
    this.main = this.svg.append("g");

    // Store the window width and height
    this.width = document.querySelector('#networkContainer').clientWidth;
    this.height = document.querySelector('#networkContainer').clientHeight;

    // Select a colour scale
    const colour = this.colourFunction;

    this.groupsAndColours = [];
    for (const key of Object.keys(groupColours)) {
      this.groupsAndColours.push({ groupName: key, colour: this.colourFunction(key) })
    }

    d3.zoom().on("zoom", () => {
      // Set the current scale
      this.main.attr("transform", d3.event.transform);

      // Update label font size
      this.label.attr('font-size', () => {
        return 14 / d3.event.transform.k;
      });
    })(this.svg as any);

    // Create a new force simulation
    this.simulation = d3.forceSimulation()
      .force("link", d3.forceLink().id((d: FigureNode) => d.id))
      .force("charge", d3.forceManyBody().strength(-200))
      .force("collide", d3.forceManyBody())
      .force("center", d3.forceCenter(this.width / 2, this.height / 2)) as d3.Simulation<FigureNode, Link>;
    // .force("x", d3.forceX())
    // .force("y", d3.forceY()) as d3.Simulation<FigureNode, Link>;

    // Link click handling
    const handleLinkClick = (d: Link) => {
      // Stop event propogation
      d3.event.stopPropagation();

      // Select the link
      this.selectLink(d);
    };

    // Define the links
    this.links = this.main.append("g")
      .attr("class", "link")
      .selectAll("path")
      .data(this.graph.links)
      .join("path")
      .attr("class", "link")
      .attr("fill", "rgba(0,0,0,0)")
      .attr("stroke", "#fff")
      .attr("stroke-width", (d: Link) => Math.pow(d.myths.length, 1.1));

    // Link event
    this.links.on('click', handleLinkClick);

    // Node click handling
    const handleNodeClick = (d: FigureNode) => {
      // Stop event propogation
      d3.event.stopPropagation();

      // Select the figure
      this.selectFigure(d.id);
    };

    // Define the nodes
    this.nodes = this.main.append("g")
      .attr("stroke", "#fff")
      .attr("stroke-width", 1)
      .selectAll("circle")
      .data(this.graph.nodes)
      .join("circle")
      .attr("class", (d: FigureNode) => d.id)
      .attr("r", (d: FigureNode) => d.size)
      .attr("fill", (d: FigureNode) => colour(d.group))
      // @ts-ignore
      .call(d3.drag()
        .on("start", (d: FigureNode) => this.nodeDragStarted(d))
        .on("drag", (d: FigureNode) => this.nodeDragged(d))
        .on("end", (d: FigureNode) => this.nodeDragEnded(d))
      );

    // Define the node selected indicator
    this.selectedNodeCircle = this.main.append("g").append("circle");

    // Define the node hovered indicator
    this.hoveringNodeCircle = this.main.append("g").append("circle");

    // Circle labels
    this.label = this.main.append("g")
      .selectAll("text.label")
      .data(this.graph.nodes)
      .enter().append("text")
      .attr("class", (d: FigureNode) => d.id)
      .attr("fill", "#fff")
      .text((d: FigureNode) => d.name)
      .style("pointer-events", "none")
      .call(d3.drag()
        .on("start", (d: FigureNode) => this.nodeDragStarted(d))
        .on("drag", (d: FigureNode) => this.nodeDragged(d))
        .on("end", (d: FigureNode) => this.nodeDragEnded(d))
      );

    // Node click event
    this.nodes.on('click', handleNodeClick);
    this.label.on('click', handleNodeClick);

    // Handle node mouse over events
    this.nodes.on('mouseover', (d: FigureNode) => {
      this.onFigureHover(d);
    });

    // Handle node mouse out events
    this.nodes.on('mouseout', (d: FigureNode) => {
      this.onFigureHoverEnd(d);
    });

    // Handle link mouse over events
    this.links.on('mouseover', (d: Link) => {
      this.onLinkHover(d);
    });

    // Handle link mouse out events
    this.links.on('mouseout', (d: Link) => {
      this.onLinkHoverEnd(d);
    });

    // Update function
    const update = () => {
      // Update node labels
      this.updateLabels();

      // Update links
      this.updateLinks();

      // Update nodes
      this.updateNodes();
    }

    // Set up update
    this.simulation.nodes(this.graph.nodes as FigureNode[]).on("tick", update);

    // Set up the force
    this.simulation.force<ForceLink<FigureNode, Link>>("link").links(this.graph.links);
  }

  /**
   * Resets the state of the visualisation
   */
  reset() {
    this.history = [];
    this.currentHistoryIndex = -1;
    this.unselectAll();
    this.cdr.markForCheck();

    // Update the network
    this.updateLabels();
    this.updateLinks();
    this.updateNodes();
  }

  /**
   * Unselect everything in the network
   */
  public unselectAll() {
    this.state = {
      selected: undefined,
      selectedType: undefined,
      selectedMyths: undefined,
      selectedFigures: undefined,
      selectedLinks: undefined
    };
    this.graph.nodes.map((n: FigureNode) => n.selected = false);
    this.graph.links.map((n: Link) => n.selected = false);
    this.graph.myths.map((n: Myth) => n.selected = false);
  }

  /**
   * Unhover everything in the network
   */
  public unhoverAll() {
    this.hovering = undefined;
    this.hoveringType = undefined;
    this.graph.nodes.map((n: FigureNode) => n.hovering = false);
    this.graph.links.map((n: Link) => n.hovering = false);
    this.graph.myths.map((n: Myth) => n.hovering = false);
  }

  /**
   * Records the current state in the history
   */
  public recordState() {
    // If the state has changed
    if (this.history.length === 0 || this.history[this.currentHistoryIndex].selected.id !== this.state.selected.id) {
      // Increment the current history index
      this.currentHistoryIndex += 1;

      // Remove items in the history ahead of the current index
      this.history.splice(this.currentHistoryIndex, this.history.length - 1)

      // Add the latest history
      this.history.push(JSON.parse(JSON.stringify(this.state)));
      this.historyUpdates.next();
    }
  }

  /**
   * Restores a state from the history
   */
  public restoreState(stateHistoryIndex: number) {
    // Update the selected state from the history at the given index
    const state = this.history[stateHistoryIndex];

    // Update the network
    switch (state.selectedType) {
      case SelectionTypeEnum.FIGURE:
        this._selectFigure((state.selected as FigureNode).id);
        break;
      case SelectionTypeEnum.LINK:
        this._selectLink((state.selected as Link));
        break;
      case SelectionTypeEnum.MYTH:
        this._selectMyth((state.selected as Myth));
        break;
    }

    // Update the history state index
    this.currentHistoryIndex = stateHistoryIndex;
  }

  /**
   * Selects a figure by ID and records the state in the state history
   * @param d 
   */
  public selectFigure(figureID: string) {
    // Select the figure
    this._selectFigure(figureID);

    // Record the new state
    this.recordState();

    // TODO: Pan and zoom to the figure
    // ...
  }

  /**
   * Selects a figure by ID
   * @param d 
   */
  public _selectFigure(figureID: string) {
    // Unselect all
    this.unselectAll();

    // Find the node with the given ID
    this.state.selected = this.graph.nodes.find((n: FigureNode) => n.id === figureID);

    // Select the clicked node
    this.state.selectedType = SelectionTypeEnum.FIGURE;

    // Store and select the associated myths
    this.state.selectedMyths = DataSourceService.getMythsForFigure(figureID);
    this.state.selectedMyths.map((m: Myth) => m.selected = true);

    // Store and select the associated links
    this.state.selectedLinks = DataSourceService.getLinksForFigure(figureID, this.graph.links);
    this.state.selectedLinks.map((l: Link) => l.selected = true);

    // Select the connected nodes
    this.state.selectedFigures = [this.state.selected, ...this.graph.nodes.filter((n: FigureNode) => this.state.selectedLinks.some((l: Link) => l.source.id === n.id || l.target.id === n.id))];
    this.state.selectedFigures.map((n: FigureNode) => n.selected = true);

    // Update the network
    this.updateLabels();
    this.updateLinks();
    this.updateNodes();
  }

  /**
   * Start hovering a figure
   * @param d 
   */
  public onFigureHover(d: FigureNode) {
    // Hover the figure
    this.hovering = d;
    this.hoveringType = SelectionTypeEnum.FIGURE;
    d.hovering = true;

    // Update which links are hovered
    const linksHovered = DataSourceService.getLinksForFigure(d.id, this.graph.links);
    linksHovered.map((l: Link) => l.hovering = true);

    // Update which figures are hovered
    const hoveredFigures = this.graph.nodes.filter((n: FigureNode) => linksHovered.some((l: Link) => l.source.id === n.id || l.target.id === n.id))
    hoveredFigures.map((n: FigureNode) => n.hovering = true);

    // Update which myths are hovered
    const hoveredMyths = this.graph.myths.filter((m) => linksHovered.some((l) => l.myths.some((mythID) => mythID === m.id)));
    hoveredMyths.map((m: Myth) => m.hovering = true);

    // Update the network
    this.updateLabels();
    this.updateNodes();
    this.updateLinks();
  }

  /**
   * Stop hovering a figure
   * @param d 
   */
  public onFigureHoverEnd(d: FigureNode) {
    // Unhover the figure
    d.hovering = false;

    // Unhover all
    this.unhoverAll();

    // Update the network
    this.updateLabels();
    this.updateNodes();
    this.updateLinks();
  }

  /**
   * Selects a link and records the state in the state history
   * @param d 
   */
  public selectLink(d: Link) {
    // Select the link
    this._selectLink(d);

    // Record the new state
    this.recordState();
  }

  /**
   * Select a link
   * @param d 
   */
  private _selectLink(d: Link) {
    // Unselect all
    this.unselectAll();

    // Select the clicked link
    this.state.selected = d;
    this.state.selectedType = SelectionTypeEnum.LINK;
    this.state.selectedLinks = this.graph.links.filter((l: Link) => l.id === d.id);
    this.state.selectedLinks.map((l: Link) => l.selected = true);

    // Store and select the associated myths
    this.state.selectedMyths = this.graph.myths.filter((m: Myth) => d.myths.some((linkMythID: string) => linkMythID === m.id));
    this.state.selectedMyths.map((m: Myth) => m.selected = true);

    // Select the connected nodes
    this.state.selectedFigures = this.graph.nodes.filter((n: FigureNode) => n.id === d.source.id || n.id === d.target.id);
    this.state.selectedFigures.map((n: FigureNode) => n.selected = true);

    // Update the network
    this.updateLabels();
    this.updateLinks();
    this.updateNodes();
  }

  /**
   * Start hovering a link
   * @param d 
   */
  public onLinkHover(d: Link) {
    // Hover the link
    d.hovering = true;
    this.hovering = d;
    this.hoveringType = SelectionTypeEnum.LINK;

    // Set connected nodes to hovering
    const hoveredNodes = this.graph.nodes.filter((n: FigureNode) => n.id === d.source.id || n.id === d.target.id);
    hoveredNodes.map((n: FigureNode) => n.hovering = true);

    // Update the network
    this.updateLabels();
    this.updateNodes();
    this.updateLinks();
  }

  /**
   * Stop hovering a link
   * @param d 
   */
  public onLinkHoverEnd(d: Link) {
    // Unhover the link
    d.hovering = false;

    // Unhover all
    this.unhoverAll();

    // Update the network
    this.updateLabels();
    this.updateNodes();
    this.updateLinks();
  }

  /**
   * Selects a myth and records the state in the state history
   * @param d 
   */
  public selectMyth(d: Myth) {
    // Select the link
    this._selectMyth(d);

    // Record the new state
    this.recordState();
  }

  /**
   * Select a myth
   * @param d 
   */
  private _selectMyth(d: Myth) {
    // Unselect all
    this.unselectAll();
    this.unhoverAll();

    // Select the clicked myth
    d.selected = true;
    this.state.selected = d;
    this.state.selectedType = SelectionTypeEnum.MYTH;

    // Store the selected myths
    this.state.selectedMyths = [d];

    // Store and select the selected figures
    this.state.selectedFigures = DataSourceService.getNodesForMyths([d], this.graph.nodes);
    this.state.selectedFigures.map((f) => f.selected = true);

    // Store and select the selected links
    this.state.selectedLinks = DataSourceService.getLinksForMyth(d, this.graph.links);
    this.state.selectedLinks.map((l) => l.selected = true);

    // Update the network
    this.updateLabels();
    this.updateLinks();
    this.updateNodes();
  }

  /**
   * Start hovering a myth
   * @param d
   */
  public onMythHover(d: Myth) {
    // Hover the myth
    d.hovering = true;

    // Find the matching links and figures and hover them
    const matching = this.graph.links.filter((link) => link.myths.some((myth) => myth === d.id));
    matching.map((link: Link) => {
      link.hovering = true;
      link.source.hovering = true;
      link.target.hovering = true;
    });

    // Update the network
    this.updateLabels();
    this.updateLinks();
    this.updateNodes();
  }

  /**
   * Stop hovering a myth
   * @param d 
   */
  public onMythHoverEnd(d: Myth) {
    // Unhover the myth
    d.hovering = false;

    // Unhover all
    this.unhoverAll();

    // Update the network
    this.updateLabels();
    this.updateLinks();
    this.updateNodes();
  }

  /**
   * Handle node drag start events
   * @param d 
   */
  nodeDragStarted(d: FigureNode) {
    if (!d3.event.active) this.simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
    d.dragging = true;
  }

  /**
   * Handle node dragging events
   * @param d 
   */
  nodeDragged(d: FigureNode) {
    d.fx = d3.event.x;
    d.fy = d3.event.y;
  }

  /**
   * Handle node drag end events
   * @param d 
   */
  nodeDragEnded(d: FigureNode) {
    if (!d3.event.active) this.simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
    d.dragging = false;
  }

  /**
   * Updates labels
   */
  updateLabels() {

    // Update whether labels are shown, positions and opacity
    this.label
      .attr("display", (d: FigureNode) => {
        const showPrimary = d.selected;
        const showSecondary = d.hovering || d.size > 7;

        if (showPrimary || showSecondary) {
          return 'block'
        } else {
          return d.hovering || d.selected ? '' : 'none'
        }
      })
      .attr('x', (d: FigureNode) => d.x + d.size + 2)
      .attr('y', (d: FigureNode) => d.y + 4)
      .attr("opacity", (d: FigureNode) => {
        if (this.state.selected && this.hovering && !d.hovering) {
          return 0.5;
        } else if (this.state.selected && !this.hovering && !d.selected) {
          return 0.5;
        }
         else {
          return 1;
        }
      });;
  }

  /**
   * Updates links
   */
  updateLinks() {

    // Update link curves
    this.links.attr("d", (d: Link) => {
      var dx = (d.target.x - d.source.x),
        dy = (d.target.y - d.source.y),
        dr = Math.sqrt(dx * dx + dy * dy) + 200;
      return "M" + d.source.x + "," + d.source.y + "A" + dr + "," + dr + " 0 0,1 " + d.target.x + "," + d.target.y;
    });

    // Update stroke width and colour
    this.links
      .attr("stroke-width", (d: Link) => {
        // Find whether the link should be highlighted
        const highlighted = d.hovering || d.selected;

        // Set the stroke width
        const strokeWidth = Math.pow(d.myths.length, 1.1);
        if (highlighted) {
          return strokeWidth + 2;
        } else {
          return strokeWidth;
        }
      })
      .attr("stroke", (d: Link) => {
        // Find whether the link should be highlighted
        const primaryHighlighted = d.selected;
        const secondaryHighlighted = d.hovering;

        // Set the stroke colour
        if (secondaryHighlighted) {
          return 'rgba(255, 255, 255, 0.5)';
        } else if (primaryHighlighted) {
          return 'rgba(255, 247, 185, 0.5)';
        } else {
          return 'rgba(255, 255, 255, 0.3)';
        }
      });
  }

  /**
   * Updates nodes
   */
  updateNodes() {
    // Update node positions
    this.nodes
      .attr("cx", (d: FigureNode) => d.x)
      .attr("cy", (d: FigureNode) => d.y)
      .attr("class", (d: FigureNode) => {
        let nodeClass = "";
        if (d.selected) {
          nodeClass += "selected ";
        }
        if (d.hovering) {
          nodeClass += "hovering ";
        }
        return nodeClass;
      })
      .attr("opacity", (d: FigureNode) => {
        if ((this.state.selected || this.hovering) && !d.selected && !d.hovering) {
          return 0.5;
        } else {
          return 1;
        }
      });

    // If a node is being hovered - display a hovering indicator over the node
    if (this.hoveringType === SelectionTypeEnum.FIGURE) {
      let hoveredNode = this.hovering as FigureNode;
      this.hoveringNodeCircle
        .attr("cx", (d: FigureNode) => hoveredNode.x)
        .attr("cy", (d: FigureNode) => hoveredNode.y)
        .attr("r", (d: FigureNode) => hoveredNode.size + 4)
        .attr("class", "hovering-figure-indicator");
    } else {
      this.hoveringNodeCircle
        .attr("cx", (d: FigureNode) => 0)
        .attr("cy", (d: FigureNode) => 0)
        .attr("r", (d: FigureNode) => 0)
        .attr("class", "");
    }

    // If a node is selected - display a selected indicator over the node
    if (this.state.selectedType === SelectionTypeEnum.FIGURE) {
      let selectedNode = this.state.selected as FigureNode;
      this.selectedNodeCircle
        .attr("cx", (d: FigureNode) => selectedNode.x)
        .attr("cy", (d: FigureNode) => selectedNode.y)
        .attr("r", (d: FigureNode) => selectedNode.size + 4)
        .attr("class", "selected-figure-indicator");
    } else {
      this.selectedNodeCircle
        .attr("cx", (d: FigureNode) => 0)
        .attr("cy", (d: FigureNode) => 0)
        .attr("r", (d: FigureNode) => 0)
        .attr("class", "");
    }
  }
}
