import { Component, OnInit, Input } from '@angular/core';
import { GroupAndColour } from 'src/app/models/groupAndColour';

@Component({
  selector: 'app-graph-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {

  @Input() groups: GroupAndColour[];

  constructor() { }

  ngOnInit(): void {
    
  }
}
