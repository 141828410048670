import { Component, Host, OnInit } from '@angular/core';
import { NetworkComponent } from '../network/network.component';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  constructor(@Host() public network: NetworkComponent) { }

  ngOnInit(): void {
  }

  reset() {
    this.network.reset();
  }
}
