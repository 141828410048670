import { Component, OnInit, Input, Host } from '@angular/core';
import { FigureNode } from 'src/app/models/node';
import { Myth } from 'src/app/models/myth';
import { NetworkComponent } from '../../network/network.component';

@Component({
  selector: 'app-figure',
  templateUrl: './figure.component.html',
  styleUrls: ['./figure.component.scss']
})
export class FigureComponent implements OnInit {

  @Input() figure: FigureNode;

  @Input() myths: Myth[];

  @Input() figures: Myth[];

  constructor(@Host() public network: NetworkComponent) {
  }

  ngOnInit(): void {
  }

  selectMyth(myth: Myth) {
    this.network.onMythHoverEnd(myth);
    this.network.selectMyth(myth)
  }

}
