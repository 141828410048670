import { Figure } from '../models/figure';

export const figures: Figure[] = [
    {
        id: "1",
        name: "Zeus",
        link: "https://www.theoi.com/Olympios/Zeus.html",
        other_names: ["Cronides", "Cronion", "Jupiter"],
        tags: ["Olympian", "King of the gods", "God of the Sky, Weather, Law and Order, Destiny and Fate"],
        description: "ZEUS was the King of the Gods and the god of the sky, weather, law and order, destiny and fate, and kingship. He was depicted as a regal, mature man with a sturdy figure and dark beard. His usual attributes were a lightning bolt, a royal sceptre and an eagle."
    },
    {
        id: "2",
        name: "Kronos",
        link: "https://www.theoi.com/Titan/TitanKronos.html",
        other_names: ["Cronus"],
        tags: ["Titan"],
        description: "KRONOS (Cronus) was the King of the Titanes and the god of time, in particular time when viewed as a destructive, all-devouring force."
    },
    {
        id: "3",
        name: "Rheia",
        link: "https://www.theoi.com/Titan/TitanisRhea.html",
        other_names: ["Cybele", "Meter Theon"],
        tags: ["Titan"],
        description: "RHEIA (Rhea) was the Titanis (Titaness) mother of the gods, and goddess of female fertility, motherhood, and generation. Her name means 'flow' and 'ease.' As the wife of Kronos (Cronus, Time), she represented the eternal flow of time and generations; as the great Mother (Meter Megale), the 'flow' was menstrual blood, birth waters, and milk. She was also a goddess of comfort and ease, a blessing reflected in the common Homeric phrase 'the gods who live at their ease (rhea).'"
    },
    {
        id: "4",
        name: "Ares",
        link: "https://www.theoi.com/Olympios/Ares.html",
        other_names: ["Enyalius", "Mars"],
        tags: ["Olympian"],
        description: "ARES was the Olympian god of war, battlelust, courage and civil order. In ancient Greek art he was depicted as either a mature, bearded warrior armed for battle, or a nude, beardless youth with a helm and spear."
    },
    {
        id: "5",
        name: "Hera",
        link: "https://www.theoi.com/Olympios/Hera.html",
        other_names: ["Juno"],
        tags: ["Olympian"],
        description: "HERA was the Olympian queen of the gods, and the goddess of marriage, women, the sky and the stars of heaven. She was usually depicted as a beautiful woman wearing a crown and holding a royal, lotus-tipped sceptre, and sometimes accompanied by a lion, cuckoo or hawk."
    },
    {
        id: "6",
        name: "Metis",
        link: "https://www.theoi.com/Titan/TitanisMetis.html",
        other_names: [],
        tags: ["Titan", "Goddess", "Good counsel", "Planning", "Cunning", "Wisdom"],
        description: "METIS was one of the elder Okeanides and the Titan-goddess of good counsel, planning, cunning and wisdom.\nIt should be noted that most poets and mythographers describe Athena as a \"motherless goddess\" and no mention is made of Metis. Zeus himself was titled Mêtieta \"the Wise Counsellor\" in the Homeric poems and in this sense Metis was probably regarded as an aspect of the god rather than a distinct figure. In any case, the Metis myth implies she was wholly subsumed by the devouring god."
    },
    {
        id: "7",
        name: "Athena",
        link: "https://www.theoi.com/Olympios/Athena.html",
        other_names: ["Pallas", "Tritogeneia", "Minerva"],
        tags: ["Olympian", "Goddess", "Wisdom", "Good Counsel", "War", "The Defence Of Towns", "Heroic Endeavour", "Weaving", "Pottery", "Crafts"],
        description: "ATHENE (Athena) was the Olympian goddess of wisdom and good counsel, war, the defence of towns, heroic endeavour, weaving, pottery and various other crafts. She was depicted as a stately woman armed with a shield and spear, and wearing a long robe, crested helm, and the famed aigis--a snake-trimmed cape adorned with the monstrous visage of the Gorgon Medousa (Medusa)."
    },
    {
        id: "8",
        name: "Hephaistos",
        link: "https://www.theoi.com/Olympios/Hephaistos.html",
        other_names: ["Vulcan"],
        tags: ["Olympian", "God", "Fire", "Smiths", "Craftsmen", "Metalworking", "Stonemasonry", "Sculpture"],
        description: "HEPHAISTOS (Hephaestus) was the Olympian god of fire, smiths, craftsmen, metalworking, stonemasonry and sculpture. He was depicted as a bearded man holding a hammer and tongs--the tools of a smith--and sometimes riding a donkey."
    },
    {
        id: "9",
        name: "Aphrodite",
        link: "https://www.theoi.com/Olympios/Aphrodite.html",
        other_names: ["Cypris", "Cytherea", "Venus"],
        tags: ["Olympian"],
        description: "APHRODITE was the Olympian goddess of love, beauty, pleasure and procreation. She was depicted as a beautiful woman often accompanied by the winged godling Eros (Love). Her attributes included a dove, apple, scallop shell and mirror. In classical sculpture and fresco she was usually depicted nude."
    },
    {
        id: "10",
        name: "Apollon",
        link: "https://www.theoi.com/Olympios/Apollon.html",
        other_names: ["Phoebus", "Apollo"],
        tags: ["Olympian"],
        description: "APOLLON (Apollo) was the Olympian god of prophecy and oracles, music, song and poetry, archery, healing, plague and disease, and the protection of the young. He was depicted as a handsome, beardless youth with long hair and attributes such as a wreath and branch of laurel, bow and quiver of arrows, raven, and lyre."
    },
    {
        id: "12",
        name: "Artemis",
        link: "https://www.theoi.com/Olympios/Artemis.html",
        other_names: ["Phoebe", "Diana"],
        tags: ["Olympian"],
        description: "ARTEMIS was the Olympian goddess of hunting, the wilderness and wild animals. She was also a goddess of childbirth, and the protectress of the girl child up to the age of marriage--her twin brother Apollon was similarly the protector of the boy child. Together the two gods were also bringers of sudden death and disease--Artemis targetted women and girls, Apollon men and boys. In ancient art Artemis was usually depicted as a girl or young maiden with a hunting bow and quiver of arrows."
    },
    {
        id: "13",
        name: "Demeter",
        link: "https://www.theoi.com/Olympios/Demeter.html",
        other_names: ["Deo", "Ceres"],
        tags: ["Olympian"],
        description: "DEMETER was the Olympian goddess of agriculture, grain and bread who sustained mankind with the earth's rich bounty. She presided over the foremost of the Mystery Cults which promised its intiates the path to a blessed afterlife in the realm of Elysium. Demeter was depicted as a mature woman, often wearing a crown and bearing sheafs of wheat or a cornucopia (horn of plenty), and a torch."
    },
    {
        id: "14",
        name: "Dionysos",
        link: "https://www.theoi.com/Olympios/Dionysos.html",
        other_names: ["Bacchus", "Lyaeus", "Liber"],
        tags: ["Olympian"],
        description: "DIONYSOS (Dionysus) was the Olympian god of wine, vegetation, pleasure, festivity, madness and wild frenzy. He was depicted as either an older, bearded god or an effeminate, long-haired youth. His attributes included the thyrsos (a pine-cone tipped staff), a drinking cup and a crown of ivy. He was usually accompanied by a troop of Satyrs and Mainades (wild female devotees)."
    },
    {
        id: "15",
        name: "Hermes",
        link: "https://www.theoi.com/Olympios/Hermes.html",
        other_names: ["Argeiphontes", "Mercury"],
        tags: ["Olympian"],
        description: "HERMES was the Olympian god of herds and flocks, travellers and hospitality, roads and trade, thievery and cunning, heralds and diplomacy, language and writing, athletic contests and gymnasiums, astronomy and astrology. He was the herald and personal messenger of Zeus, King of the Gods, and also the guide of the dead who led souls down into the underworld. Hermes was depicted as either a handsome and athletic, beardless youth or as an older, bearded man, with winged boots and a herald's wand."
    },
    {
        id: "16",
        name: "Hestia",
        link: "https://www.theoi.com/Ouranios/Hestia.html",
        other_names: ["Vesta"],
        tags: ["Olympian"],
        description: "HESTIA was the virgin goddess of the hearth (both private and municipal) and the home. As the goddess of the family hearth she also presided over the cooking of bread and the preparation of the family meal. Hestia was also the goddess of the sacrificial flame and received a share of every sacrifice to the gods. The cooking of the communal feast of sacrificial meat was naturally a part of her domain."
    },
    {
        id: "17",
        name: "Poseidon",
        link: "https://www.theoi.com/Olympios/Poseidon.html",
        other_names: ["Cronides", "Neptune"],
        tags: ["Olympian"],
        description: "POSEIDON was the Olympian god of the sea, earthquakes, floods, drought and horses. He was depicted as a mature man with a sturdy build and dark beard holding a trident (a three-pronged fisherman's spear)."
    },
    {
        id: "18",
        name: "Asklepios",
        link: "https://www.theoi.com/Ouranios/Asklepios.html",
        other_names: ["Aesculapius", "Asclepius"],
        tags: ["Olympian"],
        description: "ASKLEPIOS (Asclepius) was the god of medicine. He was also the patron god, and reputed ancestor, of the Asklepiades (Asclepiades), the ancient guild of doctors."
    },
    {
        id: "20",
        name: "Eros",
        link: "https://www.theoi.com/Ouranios/Eros.html",
        other_names: ["Cupid", "Amor"],
        tags: ["Olympian"],
        description: "EROS was the mischievous god of love, a minion and constant companion of the goddess Aphrodite. The poet Hesiod first represents him as a primordial deity who emerges self-born at the beginning of time to spur procreation. (See the Protogenos Eros and Phanes for more information.) The same poet later describes two love-gods, Eros and Himeros (Desire), accompanying Aphrodite at the time of her birth from the sea-foam. Some classical writers interpreted this to mean the pair were born of the goddess immediately following her birth or else alongside her from the sea-foam. The scene was particular popular in ancient art where the godlings flutter about the goddess as she reclines inside a conch-shell. Eventually Eros was multiplied by ancient poets and artists into a host of Erotes (Roman Cupides). The singular Eros, however, remained distinct in myth. It was he who lit the flame of love in the hearts of the gods and men, armed with either a bow and arrows or a flaming torch. Eros was often portrayed as the disobedient but fiercely loyal child of Aphrodite."
    },
    {
        id: "21",
        name: "Hebe",
        link: "https://www.theoi.com/Ouranios/Hebe.html",
        other_names: ["Dla", "Juventas"],
        tags: ["Olympian"],
        description: "HEBE was the goddess of youth and the cupbearer of the gods who served ambrosia at the heavenly feast. She was also the patron goddess of the young bride and an attendant of the goddess Aphrodite. Herakles (Heracles) received Hebe in marriage upon his ascension to Olympos, a wedding which reconciled the hero with Hebe's mother Hera."
    },
    {
        id: "22",
        name: "Horai",
        link: "https://www.theoi.com/Ouranios/Horai.html",
        other_names: [],
        tags: ["Olympian"],
        description: "THE HORAI (Horae) were the goddesses of the seasons and the natural portions of time. They presided over the revolutions of the heavenly constellations by which the year was measured, while their three sisters, the Moirae (Moirae) spinned out the web of fate. The Horai also guarded the gates of Olympos and rallied the stars and constellations of heaven."
    },
    {
        id: "23",
        name: "Iris",
        link: "https://www.theoi.com/Pontios/Iris.html",
        other_names: [],
        tags: ["Olympian"],
        description: "IRIS was the goddess of the rainbow and the messenger of the Olympian gods. She was often described as the handmaiden and personal messenger of Hera. Iris was a goddess of sea and sky--her father Thaumas 'the wondrous' was a marine-god, and her mother Elektra 'the amber' a cloud-nymph. For the coastal-dwelling Greeks, the rainbow's arc was most often seen spanning the distance beteween cloud and sea, and so the goddess was believed to replenish the rain-clouds with water from the sea. Iris had no distinctive mythology of her own. In myth she appears only as an errand-running messenger and was usually described as a virgin goddess. Her name contains a double meaning, being connected with both the Greek word iris 'the rainbow' and eiris 'messenger.'"
    },
    {
        id: "24",
        name: "Mousai",
        link: "https://www.theoi.com/Ouranios/Mousai.html",
        other_names: ["Musae"],
        tags: ["Olympian"],
        description: "THE MOUSAI (Muses) were the goddesses of music, song and dance, and the source of inspiration to poets. They were also goddesses of knowledge, who remembered all things that had come to pass. Later the Mousai were assigned specific artistic spheres: Kalliope (Calliope), epic poetry; Kleio (Clio), history; Ourania (Urania), astronomy; Thaleia (Thalia), comedy; Melpomene, tragedy; Polymnia (Polyhymnia), religious hymns; Erato, erotic poetry; Euterpe, lyric poetry; and Terpsikhore (Terpsichore), choral song and dance."
    },
    {
        id: "25",
        name: "Nike",
        link: "https://www.theoi.com/Daimon/Nike.html",
        other_names: ["Victoria"],
        tags: ["Olympian"],
        description: "NIKE (Nicé) was the winged goddess of victory--victory both in war and in peaceful competition. When Zeus was gathering allies at the start of the Titan War, Styx brought her four children Nike (Victory), Zelos (Rivalry), Kratos (Cratus Strength) and Bia (Force) into the god's service. Nike was appointed his charioteer and together the four became sentinels of Zeus' throne."
    },
    {
        id: "27",
        name: "Eileithyia",
        link: "https://www.theoi.com/Ouranios/Eileithyia.html",
        other_names: ["Ilithyia", "Lucina", "Natio"],
        tags: ["Olympian"],
        description: "EILEITHYIA (Ilithyia) was the goddess of childbirth and labour pains. According to some there were two Eileithyiai (Eileithyiae)--one who furthered birth and one who protracted the labour. Her name means 'she who comes to aid' or 'relieve' from the Greek word elêluthyia. Her Roman counterpart was Lucina ('Light bringer') or Natio ('Birth')."
    },
    {
        id: "28",
        name: "Hygeia",
        link: "https://www.theoi.com/Ouranios/AsklepiasHygeia.html",
        other_names: ["Salus"],
        tags: ["Olympian"],
        description: "HYGEIA was the goddess of good health. She was a daughter and attendant of the medicine-god Asklepios (Asclepius), and a companion of the goddess Aphrodite. Her sisters included Panakeia (Panacea) (Cure-All) and Iaso (Remedy)."
    },
    {
        id: "29",
        name: "Leto",
        link: "https://www.theoi.com/Titan/TitanisLeto.html",
        other_names: ["Latona"],
        tags: ["Olympian"],
        description: "LETO was one of the Titanides (female Titans), a bride of Zeus, and the mother of the twin gods Apollon and Artemis. She was the goddess of motherhood and, with her children, a protectress of the young. Her name and iconography suggest she was also a goddess of modesty and womanly demure. Like her sister Asteria she may also have been a goddess of the night, or alternatively of the light of day."
    },
    {
        id: "30",
        name: "Moirai",
        link: "https://www.theoi.com/Daimon/Moirai.html",
        other_names: ["Fatum", "Fatae", "Parcae"],
        tags: ["Olympian"],
        description: "THE MOIRAI (Moirae) were the three goddesses of fate who personified the inescapable destiny of man. They assigned to every person his or her fate or share in the scheme of things. Their name means 'Parts', 'Shares' or 'Alottted Portions.' The individuals were Klotho (Clotho), the 'the Spinner,' who spun the thread of life, Lakhesis (Lachesis), 'the Apportioner of Lots', who measured it, and Atropos (or Aisa), 'She who cannot be turned,' who cut it short. Zeus Moiragetes, the god of fate, was their leader."
    },
    {
        id: "31",
        name: "Themis",
        link: "https://www.theoi.com/Titan/TitanisThemis.html",
        other_names: [],
        tags: ["Titan", "Olympian"],
        description: "THEMIS was the Titan goddess of divine law and order--the traditional rules of conduct first established by the gods. She was also a prophetic goddess who presided over the most ancient oracles, including Delphoi (Delphi). In this role, she was the divine voice (themistes) who first instructed mankind in the primal laws of justice and morality, such as the precepts of piety, the rules of hospitality, good governance, conduct of assembly, and pious offerings to the gods. In Greek, the word themis referred to divine law, those rules of conduct long established by custom. Unlike the word nomos, the term was not usually used to describe laws of human decree."
    },
    {
        id: "32",
        name: "Tykhe",
        link: "https://www.theoi.com/Daimon/Tykhe.html",
        other_names: [],
        tags: ["Olympian"],
        description: "TYKHE (Tyche) was the goddess of fortune, chance, providence and fate. She was usually honoured in a more favourable light as Eutykhia (Euty chia), goddess of good fortune, luck, success and prosperity. Tykhe was depicted with a variety of attributes--holding a rudder, she was conceived as the divinity guiding and conducting the affairs of the world, and in this respect she was called one of the Moirai (Moirae, Fates); with a ball she represented the varying unsteadiness of fortune, unsteady and capable of rolling in any direction; with Ploutos (Plutus) or the cornucopia, she was the symbol of the plentiful gifts of fortune."
    },
    {
        id: "33",
        name: "Aglaea",
        link: "https://www.theoi.com/Ouranios/KharisAglaia.html",
        other_names: [],
        tags: ["Kharites", "Chairites"],
        description: "AGLAIA (Aglaea) was one of the three Kharites (Charites) and the goddess of beauty, splendour, glory and adornment."
    },
    {
        id: "34",
        name: "Aegle",
        link: "https://www.theoi.com/Ouranios/AsklepiasAigle.html",
        other_names: [],
        tags: ["Good Health"],
        description: "AIGLE (Aegle) was the goddess of radiant good health. She was an attendant of her father, the medicine-god Asklepios (Asclepius). Her sisters included Panakeia (All-Cure), Iaso (Remedy) and Hygeia (Good-Health)."
    },
    {
        id: "35",
        name: "Tartaros",
        link: "https://www.theoi.com/Protogenos/Tartaros.html",
        other_names: [],
        tags: ["Primordial"],
        description: "TARTAROS (Tartarus) was the primordial god (protogenos) of the stormy pit of Tartaros that lies beneath the foundations of the earth. He was the body of the pit itself rather than an athropomorphic deity. Tartaros was envisaged as the opposite of the sky, an inverted-dome lying beneath the flat earth. Together the Ouranion-dome and Tartarean-pit enclosed the entire cosmos in an egg-shaped or spherical shell."
    }, 
    {
        id: "36",
        name: "Kyklopes",
        link: "https://www.theoi.com/Titan/Kyklopes.html",
        other_names: ["Cyclops", "Cyclopes"],
        tags: ["Giants", "Immortal"],
        description: "THE ELDER KYKLOPES (Cyclopes) were three, orb-eyed, immortal giants who forged the lightning-bolts of Zeus. As soon as they were born, their father Ouranos (Uranus, the Sky) locked them away inside the belly of Earth, along with their stormy brothers, the hundred-handed Hekatonkheires (Hecatoncheires)."
    },
    {
        id: "37",
        name: "Hekatonkheires",
        link: "https://www.theoi.com/Titan/Hekatonkheires.html",
        other_names: ["Hecatoncheires"],
        tags: ["Primordial", "Giants"],
        description: "THE HEKATONKHEIRES (Hecatoncheires) or Hundred-Handed giants were three primordial sons of Ouranos (Uranus, the Sky) and Gaia (Gaea, the Earth). Each had a hundred hands for wielding clouds and fifty heads for blustering winds (theullai). Their three companion brothers, the Kyklopes (Cyclopes), were masters of thunder and lightning. Fearing the power of his gigantic sons, Ouranos promptly locked them away in the pit of Tartaros. An age later, the six giants were released by Zeus during his war against the Titanes (Titans) and helped drive the elder gods from heaven down into the pit. The Hekatonkheires were then appointed as the prison's eternal wardens."
    },
    {
        id: "38",
        name: "Haides",
        link: "https://www.theoi.com/Khthonios/Haides.html",
        other_names: ["Hades", "Plouton"],
        tags: ["King", "Underworld", "God of the Dead"],
        description: "HAIDES (Hades) was the king of the underworld and god of the dead. He presided over funeral rites and defended the right of the dead to due burial. Haides was also the god of the hidden wealth of the earth, from the fertile soil with nourished the seed-grain, to the mined wealth of gold, silver and other metals."
    },
    {
        id: "39",
        name: "Prometheus",
        link: "https://www.theoi.com/Titan/TitanPrometheus.html",
        other_names: ["Prometheus"],
        tags: ["Titan", "Forethought", "Crafty Counsel"],
        description: "PROMETHEUS was the Titan god of forethought and crafty counsel who was given the task of moulding mankind out of clay."
    },
    {
        id: "40",
        name: "Pandora",
        link: "https://www.theoi.com/Heroine/Pandora.html",
        other_names: [],
        tags: ["Mortal"],
        description: "PANDORA was the first mortal woman who was formed out of clay by the gods."
    },
    {
        id: "41",
        name: "Mankind",
        other_names: ["Humans"],
        tags: ["Mortal"],
        description: "Created by the Titan god Prometheus out of clay."
    },
    {
        id: "42",
        name: "Gaia",
        link: "https://www.theoi.com/Protogenos/Gaia.html",
        other_names: ["Gaea", "Terra", "Tellus", "Earth"],
        tags: ["Primordial", "Giants"],
        description: "GAIA (Gaea) was the goddess of the earth. She was one of the primoridal elemental deities (protogenoi) born at the dawn of creation. Gaia was the great mother of all creation--the heavenly gods were descended from her through her union with Ouranos (Uranus) (Sky), the sea-gods from her union with Pontos (Sea), the Gigantes (Giants) from her mating with Tartaros (the Pit), and mortal creatures born directly from her earthy flesh."
    },
    {
        id: "43",
        name: "Typhoeus",
        link: "https://www.theoi.com/Gigante/Typhoeus.html",
        other_names: ["Typhon"],
        tags: ["Giants", "Storm Giant"],
        description: "TYPHOEUS (Typhon) was a monstrous storm-giant who laid siege to heaven but was defeated by Zeus and imprisoned in the pit of Tartaros. He was the source of devastating storms which issued forth from that dark nether-realm. Later poets describe him as a volcano-giant, trapped beneath the weight of Mount Aitna (Etna) in Sicily."
    },
    {
        id: "44",
        name: "Pan",
        link: "https://www.theoi.com/Georgikos/Pan.html",
        other_names: ["Fauncus", "Inuus"],
        tags: ["Rustic God", "Shepherds", "Hunters"],
        description: "PAN was the god of shepherds and hunters, and of the meadows and forests of the mountain wilds. His unseen presence aroused panic in those who traversed his realm."
    },
    {
        id: "45",
        name: "Heracles",
        link: "https://www.theoi.com/greek-mythology/heracles.html",
        other_names: ["Herakles"],
        tags: ["Hero", "Olympian", "Mortal"],
        description: "Heracles was a divine hero in Greek mythology, the son of Zeus and Alcmene, foster son of Amphitryon."
    },
    {
        id: "46",
        name: "Amalthea",
        link: "https://www.theoi.com/Ther/AixAmaltheia.html",
        other_names: ["Amaltheia", "Amalthea"],
        tags: ["She-Goat", "Nurse"],
        description: "AMALTHEIA (Amalthea) was the she-goat nurse of the god Zeus who nourished him with milk in a cave on Mount Dikte (Dicte) in Krete (Crete). When the god reached maturity he created his thunder-shield (the aigis) from her hide and the horn of plenty (cornucopia or keras amaltheias) from her crown. According to some Amaltheia was a nymphe rather than a goat."
    },
    {
        id: "47",
        name: "Tantalos",
        link: "https://www.theoi.com/greek-mythology/deified-mortals.html",
        other_names: ["Tantalus"],
        tags: ["Deified Mortal", "Mortal", "Lydian", "King"],
        description: "TANTALUS (Tantalos) A Lydian king who was favoured by the gods and invited to dine at their table. But after he stole ambrosia and nectar, he was condemned to spend eternity tortured in the Dungeons of the Damned. (N.B. The theft of ambrosia, literally \"immortality,\" suggests he cheated death. Cf. Sisyphus.)"
    },
    {
        id: "48",
        name: "Lykaon",
        link: "https://www.theoi.com/Heros/Lykaon.html",
        other_names: ["Lycaon"],
        tags: ["Deified Mortal","Mortal", "Arkadia", "King"],
        description: "LYKAON (Lycaon) was an early king of Arkadia who lived in the time before the Great Deluge. He sought to test the divinity of Zeus by serving the god a slaughtered child--either his son Nyktimos (Nyctimus), his grandson Arkas (Arcas), or a Molossian captive. Zeus was furious and overturning the table, destroyed the fifty sons of Lykaon with lightning-bolts, and transformed the king into a wolf."
    },
    {
        id: "49",
        name: "Ixion",
        link: "https://www.britannica.com/topic/Ixion-Greek-mythology",
        other_names: [],
        tags: ["Deified Mortal", "Mortal", "Lapiths", "King"],
        description: "Ixion, in Greek legend, son either of the god Ares or of Phlegyas, king of the Lapiths in Thessaly.",
    },
    {
        id: "50",
        name: "Salmoneus",
        link: "https://www.theoi.com/Heros/Salmoneus.html",
        other_names: [],
        tags: ["Deified Mortal", "Mortal", "Aiolos", "King"],
        description: "SALMONEUS was one of the seven sons of King Aiolos (Aeolus) of Thessalia who led a group of colonists to the Peloponnese where he established the kingdom of Salmonia in the region later known as Pylos (in southern Elis and north-western Messenia)."
    },
    {
        id: "51",
        name: "Persephone",
        link: "https://www.theoi.com/Khthonios/Persephone.html",
        other_names: ["Proserpina"],
        tags: ["Underworld", "Goddess", "Queen of the Underworld"],
        description: "PERSEPHONE was the goddess queen of the underworld, wife of the god Haides (Hades). She was also the goddess of spring growth, who was worshipped alongside her mother Demeter in the Eleusinian Mysteries. This agricultural-based cult promised its initiates passage to a blessed afterlife."
    },
    {
        id: "52",
        name: "Tisiphone",
        link: "https://www.theoi.com/Khthonios/Erinyes.html",
        other_names: ["Erinyes", "Tilphousia"],
        tags: ["Erinyes"],
        description: "THE ERINYES (Furies) were three goddesses of vengeance and retribution who punished men for crimes against the natural order. They were particularly concerned with homicide, unfilial conduct, offenses against the gods, and perjury. A victim seeking justice could call down the curse of the Erinys upon the criminal. The most powerful of these was the curse of the parent upon the child--for the Erinyes were born of just such a crime, being sprung from the blood of Ouranos (Uranus), when he was castrated by his son Kronos (Cronus)."
    },
    {
        id: "53",
        name: "Megaira",
        link: "https://www.theoi.com/Khthonios/Erinyes.html",
        other_names: ["Erinyes"],
        tags: ["Erinyes"],
        description: "THE ERINYES (Furies) were three goddesses of vengeance and retribution who punished men for crimes against the natural order. They were particularly concerned with homicide, unfilial conduct, offenses against the gods, and perjury. A victim seeking justice could call down the curse of the Erinys upon the criminal. The most powerful of these was the curse of the parent upon the child--for the Erinyes were born of just such a crime, being sprung from the blood of Ouranos (Uranus), when he was castrated by his son Kronos (Cronus)."
    },
    {
        id: "54",
        name: "Alekto",
        link: "https://www.theoi.com/Khthonios/Erinyes.html",
        other_names: ["Erinyes"],
        tags: ["Erinyes"],
        description: "THE ERINYES (Furies) were three goddesses of vengeance and retribution who punished men for crimes against the natural order. They were particularly concerned with homicide, unfilial conduct, offenses against the gods, and perjury. A victim seeking justice could call down the curse of the Erinys upon the criminal. The most powerful of these was the curse of the parent upon the child--for the Erinyes were born of just such a crime, being sprung from the blood of Ouranos (Uranus), when he was castrated by his son Kronos (Cronus)."
    },
    {
        id: "55",
        name: "Zagreus",
        link: "https://www.theoi.com/Georgikos/Zagreus.html",
        other_names: [],
        tags: ["God", "Orphic Myseries"],
        description: "ZAGREUS, \"the first-born Dionysos,\" was a god of the Orphic Mysteries. He was a son of Zeus and Persephone who had been seduced by the god in the guise of a serpent. Zeus placed Zagreus upon the throne of heaven and armed him with his lightning bolts. The Titanes, incited by the jealous goddess Hera, sneaked into Olympos and offered the boy a collection of toys, tricking him into setting aside the lightning. They then seized and dismembered him with their knives. Zeus recovered Zagreus' heart and made it into a potion for Semele to inbibe who then conceived and gave birth to the second Dionysos as a reincarnation of the first.",
    },
    {
        id: "56",
        name: "Melinoe",
        link: "https://www.theoi.com/Khthonios/Melinoe.html",
        other_names: [],
        tags: ["Goddess", "Ghosts of the dead"],
        description: "MELINOE was a frightful, underworld goddess who presided over propitiations offered to the ghosts of the dead. At night she wandered the earth with her train of ghosts, striking fear into the hearts of men. Her limbs were black on one side and white on the other, a manifestation of her dual chthonic and heavenly nature.\nMelinoe's name means either \"Dark-Minded\" or \"Propitation-Minded\" from the Greek words melas \"black\", meilia \"propitiation\" and noos \"mind\". The word meilia was often used to describe propitiatory offerings made to the ghosts of the dead.\nMelinoe was probably an Orphic title for the goddess Hekate who was also a leader of ghosts and sometimes described as the daughter of Persephone."
    },
    {
        id: "57",
        name: "Macaria",
        link: "https://www.theoi.com/Khthonios/Makaria.html",
        other_names: ["Makaria"],
        tags: ["Goddess", "Blessed death"],
        description: "MAKARIA (Macaria) was the goddess of a \"blessed\" death, a minion of her father Haides. She was perhaps a more merciful counterpart of the death-god Thanatos or else connected with the passage of souls to the Islands of the Blessed (Nesoi Makarioi)."
    },
    {
        id: "58",
        name: "Minthe",
        link: "https://www.theoi.com/Nymphe/NympheMinthe.html",
        other_names: ["Mintha"],
        tags: ["Naiad", "Nymph"],
        description: "MINTHE was a Naiad-nymph of Mount Mintha in Elis (southern Greece) loved by the god Haides. When she claimed to be superior to his wife Persephone, the angry goddess transformed her into a mint plant.\nThe mint was probably used in ancient funerary rites to mask the smell of the dead and so came to be regarded as a sacred plant of Haides."
    },
    {
        id: "59",
        name: "Leuke",
        link: "https://www.theoi.com/Nymphe/NympheLeuke.html",
        other_names: ["Leuce"],
        tags: ["Okeanid", "Nymph"],
        description: "LEUKE (Leuce) was an Okeanid-nymph abductedto Elysion (Elysium) by the god Haides where she was transformed into a white poplar tree.\nHer story was probably connected with the white poplars which grew on the banks of the river Akheron (Acheron) in Thesprotia--a region famed for its necromantic oracle of Haides.\nLeuke was probably the same as Leukippe, an Okeanid-nymph companion of the goddess Persephone mentioned in the Homeric Hymns."
    },
    {
        id: "60",
        name: "Orpheus",
        link: "https://www.theoi.com/articles/the-tragic-myth-about-orpheus-and-eurydice/",
        other_names: ["Mortal"],
        tags: ["Musician", "Poet", "Prophet"],
        description: "Orpheus was a legendary musician, poet, and prophet in ancient Greek religion.\n\n\"It was believed by Aristotle that Orpheus never existed; but to all other ancient writers he was a real person, though living in remote antiquity. Most of them believed that he lived several generations before Homer\""
    },
    {
        id: "61",
        name: "Eurydice",
        link: "https://www.theoi.com/articles/the-tragic-myth-about-orpheus-and-eurydice/",
        other_names: ["Eurydike"],
        tags: ["Mortal"],
        description: "In Greek mythology, Eurydice or Eurydike was the wife of Orpheus, who tried to bring her back from the dead with his enchanting music"
    },
    {
        id: "62",
        name: "Kerberos",
        link: "https://www.theoi.com/Ther/KuonKerberos.html",
        other_names: ["Cerberus"],
        tags: ["Hound", "Underworld"],
        description: "KERBEROS (Cerberus) was the gigantic, three-headed hound of Haides which guarded the gates of the underworld and prevented the escape of the shades of the dead.\n\nKerberos was depicted as a three-headed dog with a serpent\'s tail, mane of snakes, and a lion\'s claws. According to some he had fifty heads although this count may have included the serpents of his mane.\n\nHerakles (Heracles) was sent to fetch Kerberos as one of his twelve labours, a task which he accomplished with the aid of the goddess Persephone.\n\nKerberos\' name perhaps means \"Death-Daemon of the Dark\" from the ancient Greek words kêr and erebos."
    },
    {
        id: "63",
        name: "Alcestis",
        link: "https://www.britannica.com/topic/Alcestis-Greek-mythology",
        other_names: [],
        tags: ["Mortal"],
        description: "Alcestis, in Greek legend, the beautiful daughter of Pelias, king of Iolcos. She is the heroine of the eponymous play by the dramatist Euripides (c. 484–406 BCE). According to legend, the god Apollo helped Admetus, son of the king of Pherae, to harness a lion and a boar to a chariot in order to win Alcestis’s hand."
    },
    {
        id: "64",
        name: "Sisyphus",
        link: "https://en.wikipedia.org/wiki/Sisyphus",
        other_names: ["Sisyphos"],
        tags: ["Mortal", "King"],
        description: "In Greek mythology, Sisyphus or Sisyphos was the king of Ephyra (now known as Corinth). He was punished for his self-aggrandizing craftiness and deceitfulness by being forced to roll an immense boulder up a hill only for it to roll down every time it neared the top, repeating this action for eternity. Through the classical influence on modern culture, tasks that are both laborious and futile are therefore described as Sisyphean."
    },
    {
        id: "65",
        name: "Pirithous",
        link: "https://en.wikipedia.org/wiki/Pirithous",
        other_names: [],
        tags: ["Mortal", "King"],
        description: "Pirithous, \'to run around\'; also transliterated as Perithous), in Greek mythology, was the King of the Lapiths of Larissa in Thessaly, as well as best friend to Theseus.",
    },
    {
        id: "66",
        name: "Theseus",
        link: "https://www.theoi.com/articles/greek-mythology-who-is-theseus/",
        other_names: [],
        tags: ["Mortal", "King"],
        description: "Theseus was the mythical king and founder-hero of Athens. Like Perseus, Cadmus, or Heracles, Theseus battled and overcame foes that were identified with an archaic religious and social order. His role in history has been called \"a major cultural transition, like the making of the new Olympia by Hercules.\""
    },
    {
        id: "67",
        name: "Creon",
        link: "https://en.wikipedia.org/wiki/Creon#:~:text=Creon%20(%2F%CB%88kri%CB%90,Menoeceus)%2C%20Lycomedes%20and%20Haimon.",
        other_names: ["Kreon"],
        tags: ["Mortal", "King"],
        description: "Creon is a figure in Greek mythology best known as the ruler of Thebes in the legend of Oedipus. He had four sons and three daughters with his wife, Eurydice (sometimes known as Henioche): Henioche, Pyrrha, Megareus (also called Menoeceus), Lycomedes and Haimon. Creon and his sister, Jocasta, were descendants of Cadmus and of the Spartoi. He is sometimes considered to be the same person who purified Amphitryon of the murder of his uncle Electryon and father of Megara, first wife of Heracles."
    },
    {
        id: "68",
        name: "Menippe",
        link: "https://www.theoi.com/Nymphe/NymphaiKoronides.html",
        other_names: ["Koronides", "Coronides", "Coronis"],
        description: "Menippe was one of the two Koronides. THE KORONIDES (Coronides) were two nymph daughters of the giant Orion. When the land of Boiotia (Boeotia) was struck by drought they voluntarily offered themselves up as a sacrifice to the gods, bashing out their own brains with shuttles. Persephone in pity then turned them into a pair of comets.",
        tags: ["Nymph", "Comet"]
    }, 
    {
        id: "69",
        name: "Metiokhe",
        link: "https://www.theoi.com/Nymphe/NymphaiKoronides.html",
        other_names: ["Koronides", "Coronides"],
        description: "Metiokhe was one of the two Koronides. THE KORONIDES (Coronides) were two nymph daughters of the giant Orion. When the land of Boiotia (Boeotia) was struck by drought they voluntarily offered themselves up as a sacrifice to the gods, bashing out their own brains with shuttles. Persephone in pity then turned them into a pair of comets.",
        tags: ["Nymph", "Comet"]
    },
    {
        id: "70",
        name: "Polybotes",
        link: "https://www.theoi.com/Gigante/GigantePolybotes.html",
        other_names: [],
        tags: ["Giant"],
        description: "POLYBOTES was one of the Gigantes (Giants) who battled Poseidon in the war against the gods. He was pursued across the sea and crushed beneath the rock of Nisyros near the island of Kos (Cos).\n\nHis name means \"fertile\" from the Greek word polybôtos or, more literally, \"feeding-many\" from the words poly- and bôtos.\n\n"
    },
    {
        id: "71",
        name: "Laomedon",
        link: "https://www.britannica.com/topic/Laomedon",
        other_names: [],
        tags: ["King", "Mortal", "King of Troy"],
        description: "Laomedon, legendary king of Troy, son of Ilus and Eurydice and father of Podarces (later famous as King Priam of Troy). He brought about his own destruction by not keeping his word."
    },
    {
        id: "72",
        name: "Ketos Aithiopios",
        link: "https://www.theoi.com/Ther/KetosAithiopios.html",
        other_names: ["Cetus Aethiopius"],
        tags: ["Monster", "Sea Monster"],
        description: "THE KETOS AITHIOPIOS (Cetus Aethiopius) was a sea-monster sent by Poseidon to ravage the land of Aithiopia (Ethiopia) as punishment for Queen Kassiopeia\'s (Cassiopeia\'s) hubristic boast that her daughter Andromeda was more beautiful than the Nereides. To assuage the wrath of the sea-gods, the girl was chained to a rock as a sacrificial feast for the monster. It was at this time that Perseus was winging his way back to Greece with the head of Medousa (Medusa). He spied the girl, flew down, slew the monster and carried her off as his bride. Some say the hero turned the monster to stone--a rock near the Lebanese town of Joppa frequented by ancient tourists.\n\nThe Sea-Monster, along with Perseus, Andromeda, and her parents King Kepheus (Cepehus) and Queen Kassiopeia, were placed amongst the stars of the sky as constellatons."
    },
    {
        id: "73",
        name: "Polyphemos",
        link: "https://www.theoi.com/Gigante/GigantePolyphemos.html#:~:text=POLYPHEMOS%20(Polyphemus)%20was%20a%20man,burning%20stake%20into%20his%20eye.",
        other_names: ["Polyphemus"],
        tags: ["Kyklops", "Cyclops", "Giant", "Man-eating Kyklops", "Monster"],
        description: "POLYPHEMOS (Polyphemus) was a man-eating kyklops (cyclops) giant--a monster with a single, orb-shaped eye in the middle of his forehead. Odysseus encountered him on his return from Troy and became trapped in the giant\'s cave. To escape the hero plied him with wine and as he slept plunged a burning stake into his eye. The blinded giant tried to prevent Odysseus\' flight by tossing boulders at his ship but, failing that, prayed to his father Poseidon to exact revenge.\n\nPolyphemos also loved the nereid-nymph Galateia and wooed her with music and song. She spurned him for the love of the shepherd Akis (Acis), but when the giant spied the pair together he crushed the boy beneath a stone."
    },
    {
        id: "74",
        name: "Odysseus",
        link: "https://www.greekmythology.com/Myths/Heroes/Odysseus/odysseus.html",
        other_names: [],
        tags: ["Hero", "Mortal", "Legendary"],
        description: "Odysseus was a legendary hero in Greek mythology, king of the island of Ithaca and the main protagonist of Homer\'s epic, the “Odyssey.” The son of Laertes and Anticlea, Odysseus was well known among the Greeks as a most eloquent speaker, an ingenious and cunning trickster."
    },
    // https://www.theoi.com/Olympios/Poseidon.html#Family
];
