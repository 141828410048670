import { Figure } from './figure';
import { SimulationNodeDatum } from 'd3-force'

export class FigureNode implements Figure, SimulationNodeDatum  {
    id: string;
    name: string;
    other_names: string[];
    tags: string[];
    description: string;
    link?: string;
    index?: number;
    x?: number;
    y?: number;
    vx?: number;
    vy?: number;
    fx?: number;
    fy?: number;
    group: string;
    size: number;
    hovering?: boolean;
    selected?: boolean;
    dragging?: boolean;
}